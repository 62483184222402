<template>
  <div class="content_center">
    กำลังโหลด...
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportType: this.$route.query.reportType,
      id: this.$route.query.id
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Detail })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    var requestOptions = {
      method: "POST",
      redirect: "follow"
    };

    fetch(
      `${this.$API_DEV_LINE_SKL}/notification/${this.reportType}/${this.id}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.result == 1) {
          window.location.href = result.detail.url;
        }
      })
      .catch(error => console.error("error", error));
  }
};
</script>

<style scoped>
.content_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
